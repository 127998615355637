




import Vue from 'vue';
import Component from 'vue-class-component';
import NewCurve from '@/pages/schedules/components/curves/NewCurve.vue';

@Component({
  components: {
    NewCurve
  }
})
export default class CreateCurve extends Vue{
}
